import React from "react";
import Select from "react-select";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import { FieldProps } from "formik";
import { ValueType } from "react-select";
import { flatten } from "lodash"

import SelectOption from "./helpers/SelectOption";

import FormikFieldCustomProps from "./helpers/FormikFieldCustomProps";

const ReactSelect: React.FC<FormikFieldCustomProps & FieldProps> = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue },
  ...props
}) => {
  let error = errors[field.name];
  let touch = touched[field.name];
  const getValue = () => {
    if (props.options) {
      return props.isMulti
        ? props.options.filter(option => field.value.indexOf(option.value) >= 0)
        : (
          props.options[0].options
            ? flatten(props.options.map(o => o.options)).find(option => option.value === field.value)
            : props.options.find(option => option.value === field.value)
          )
    } else {  
      return props.isMulti ? [] : ("" as any);
    }
  };
  // let sortedOptions = props.options
  //   ? props.options //.sort((a: SelectOption, b: SelectOption) => a.label.localeCompare(b.label))
  //   : [];
  return (
    <FormGroup>
      {props.label && (
        <Label htmlFor={field.name} className={"label-color control-label"}>
          {props.label}
          {props.required && <span className="text-danger">*</span>}
        </Label>
      )}
      <Select
        {...field}
        {...props}
        name={field.name}
        value={getValue()}
        joinValues={true}
        // ValueType<SelectOption> &
        // defaultMenuIsOpen={true}
        onChange={(
          selectedOptions: ValueType<SelectOption | SelectOption[]>
        ) => {
          if (selectedOptions) {
            setFieldValue(
              field.name,
              props.isMulti
                ? (selectedOptions as SelectOption[]).map(
                    (item: SelectOption) => item.value
                  )
                : (selectedOptions as SelectOption).value
            );
          } else {
            setFieldValue(field.name, props.isMulti ? [] : "");
          }
        }}
        invalid={Boolean(touched[field.name] && errors[field.name])}
        closeMenuOnSelect={props.isMulti ? false : true}
        className="react-select-container"
        classNamePrefix="react-select"
        error={touch && error && <FormFeedback>{error}</FormFeedback>}
        options={props.options}
      />
      {/* <pre>{JSON.stringify({ v: field.value, o: props.options }, null, 2)}</pre> */}
      {error && <div className="error-sel">{error}</div>}
    </FormGroup>
  );
};

export default ReactSelect;
