import React, { useState } from 'react';
import Amplify, { API, Storage } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { Formik, Form, Field } from 'formik';
import { Row, Col, Container, Button, Table, Alert } from 'reactstrap';

import { Input } from './components/formik';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const Admin = () => {
  const [details, setDetails] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  return <AmplifyAuthenticator usernameAlias="email">
    <Container>
      <Row>
        <Col>
          <br />
          <Formik
            initialValues={{ id: '' }}
            onSubmit={async ({ id }) => {
              setDetails(null)
              setLoading(true)
              const data = await API.get('htwRestApi', `/teleconsultation/${id}`, {})
              setLoading(false)
              if(data.length === 0) {
                alert('Invalid Order Id')
                return
              }
              setDetails(data[0])
            }}>
            {() => {
              return (
                <Form>
                  <Row className="m-t-20">
                    <Col md={9}>
                      <Field
                        type="text"
                        name="id"
                        required
                        component={Input}
                        placeholder="Enter Order ID from the email"
                      />
                    </Col>
                    <Col md={3}>
                      <Button block type="submit" color="primary">View Details</Button>
                    </Col>
                  </Row>
                </Form>
              )
            }}
          </Formik>
        </Col>
      </Row>
      {
        details ? 
        <Table responsive striped borderless hover>
          <tbody>
            <tr>
              <td style={{ width: '300px' }}>Name:</td>
              <th>{details.name}</th>
            </tr>
            <tr>
              <td>Age:</td>
              <th>{details.age}</th>
            </tr>
            <tr>
              <td>Gender:</td>
              <th>{details.gender}</th>
            </tr>
            <tr>
              <td>City:</td>
              <th>{details.city}</th>
            </tr>
            <tr>
              <td>Nationality:</td>
              <th>{details.country}</th>
            </tr>
            <tr>
              <td>Email:</td>
              <th>{details.email}</th>
            </tr>
            <tr>
              <td>Phone:</td>
              <th>{details.phone}</th>
            </tr>
            <tr>
              <td>Passport No./Aadhar Card No:</td>
              <th>{details.verification}</th>
            </tr>
            <tr>
              <td>Doctor</td>
              <th>{details.doctor}</th>
            </tr>
            <tr>
              <td>Preferred Schedule:</td>
              <th>{details.schedule}</th>
            </tr>
            <tr>
              <td>Preferred Mode:</td>
              <th>{details.mode}</th>
            </tr>
            <tr>
              <td>Present Complaint:</td>
              <th>{details.presentComplaint}</th>
            </tr>
            <tr>
              <td>Latest Reports:</td>
              <th>
                {
                  details.latestReports ? 
                  <Button color="primary" size="sm" onClick={() => {
                    Storage.get(details.latestReports, { expires: 60 })
                      .then(result => {
                        // @ts-ignore
                        window.open(result)
                      })
                      .catch(err => alert('There was an error while fetching the file'));
                  }}>Download File</Button>
                  : "No file"
                }
              </th>
            </tr>
            <tr>
              <td>Medical History:</td>
              <th>{details.medicalHistory}</th>
            </tr>
            <tr>
              <td>Preferred Language:</td>
              <th>{details.language}</th>
            </tr>
          </tbody>
        </Table>
      : (
        loading
          ? <Alert color="warning">Fetching order details</Alert>
          : <Alert color="info">Submit the form above to view order details</Alert>
        )
      }
      <hr />
      <AmplifySignOut />
    </Container>
  </AmplifyAuthenticator>
}

export default Admin;
