import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'reactstrap'

import * as serviceWorker from './serviceWorker';

import TeleConsultation from './tele-consultation';
import Admin from './admin';

const renderTeleconsultationDiv = function (instance: Element | null, doctor = "", mode = "") {
    ReactDOM.render(
        <React.StrictMode>
            <TeleConsultation
                success={(instance && instance.getAttribute('data-redirect')) || "https://www.healthtravellersworldwide.com/"}
                doctor={doctor}
                mode={mode}
            />
        </React.StrictMode>,
        instance
    );
}

// tele consultation page
const devRoot = document.getElementById('dev-root');
if(devRoot) {
    renderTeleconsultationDiv(devRoot);
}

// admin
const devAdmin = document.getElementById('dev-admin');
if(devAdmin) {
    ReactDOM.render(
        <React.StrictMode>
            <Container>
                <Admin />
            </Container>
        </React.StrictMode>,
        devAdmin
    );
}

// @ts-ignore
window.rawReactComponents = {
    loadTeleconsultation: () => {
        const instance = document.getElementById("react-teleconsultation");
        if(instance) {
            const doctorSelectors = document.getElementsByClassName('react-doctor-selector')
            for(var i = 0; i < doctorSelectors.length; i++) {
                const doctorSelector = doctorSelectors[i] as HTMLElement;
                doctorSelector.onclick = function() {
                    renderTeleconsultationDiv(instance, doctorSelector.getAttribute('data-tc-doctor') || "", doctorSelector.getAttribute('data-tc-mode') || "");
                }
            }
            renderTeleconsultationDiv(instance);
        }
    },
}

// window.rawReactComponents.loadTeleconsultation();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
