import React from 'react';
import { FormFeedback, FormGroup, Input as RInput, Label } from "reactstrap";
import { FieldProps } from 'formik'

import FormikFieldCustomProps from './helpers/FormikFieldCustomProps'

const Input: React.FC<FormikFieldCustomProps & FieldProps> = ({
    field: {...fields},
    form: {touched, errors, ...rest},
    ...props
  }) => (
  <FormGroup>
    {
      props.label &&
      <Label for={fields.name}>
      {props.label}
      {props.required && <span className="text-danger">*</span>}
    </Label>
    }
    <RInput
      {...props}
      {...fields}
      onChange={e => fields.onChange(e)}
      invalid={Boolean(touched[fields.name] && errors[fields.name])}/>
    {props.formtext && <span className="help">{props.formtext}</span>}
    {touched[fields.name] && errors[fields.name] ? <FormFeedback>{errors[fields.name]}</FormFeedback> : ''}
  </FormGroup>
);


export default Input;
