// @glo
import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalBody, Alert } from 'reactstrap';
import { Formik, Field, Form } from 'formik';
import Amplify, { API } from 'aws-amplify';
import * as yup from 'yup';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { Input, ReactSelect, RadioButton, RadioButtonGroup, YesNo, FileUpload } from './components/formik';
import { countries, doctors, schedule, genders, modes, payment_countries } from './helpers';
import StripeCheckoutForm from './components/stripe-checkout-form';

import awsconfig from './aws-exports';

import './tele-consultation.css';

Amplify.configure(awsconfig);

declare var process : {
  env: {
    REACT_APP_STAGING_STRIPE_ID: string
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STAGING_STRIPE_ID);

const TeleConsultation: React.FC<{ success: string, doctor: string, mode: string }> = ({ success, doctor, mode }) => {
  // const [showBtns, setShowBtns] = React.useState(false);
  const [stripeModal, setStripeModal] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState("");
  const [stripeAmount, setStripeAmount] = useState(0);
  const [processing, setProcessing] = useState(false);

  const ValidationSchema = yup.object().shape({
    name: yup.string().required(),
    age: yup.string().required(),
    gender: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    email: yup.string().required(),
    phone: yup.number().required(),
    verification: yup.string().label("Passport No./Aadhar Card No").required(),
    doctor: yup.string().required(),
    schedule: yup.string().required(),
    language: yup.string().required(),
    payment_country: yup.string().required(),
    line1: yup.string().when('payment_country', {
      is: (payment_country: string) => payment_country !== "IN",
      then: yup.string()
        .required('Address is required')
        .typeError('Address is required')
    }),
    terms: yup.boolean().required().oneOf([true], "The terms and conditions must be accepted."),
  })
  return (
    <div className="service-content">
      <h3 className="header-line block gold black-line" style={{ borderColor: "#666666" }}>Register for Tele-consultation</h3>
      <div className="service-form">
        <Formik
          initialValues={{
            name: '',
            age: '',
            gender: '',
            city: '',
            country: '',
            email: '',
            phone: '',
            verification: '',
            doctor,
            mode,
            schedule: '',
            latestReports: '',
            medicalHistory: '',
            presentComplaint: '',
            language: 'English',
            payment_country: 'IN',
            line1: "",
            terms: true,
          }}
          validationSchema={ValidationSchema}
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            try {
              setSubmitting(true)
              const response = await API
                .post('htwRestApi', '/teleconsultation', {
                  body: values,
                  headers: {}
                })

              if(values.payment_country === 'IN') {
                // razorpay
                var options = {
                  ...response.data,
                  handler: async (response: any) => {
                    // confirm payment
                    setProcessing(true);
                    await API
                      .put('htwRestApi', `/confirmpayment/${response.razorpay_order_id}`, {
                        body: {
                          paymentId: response.razorpay_payment_id,
                          signature: response.razorpay_signature
                        },
                        headers: {}
                      })
                      setSubmitting(false)
                    // redirect to payment success page
                    window.location.href = success;
                  },
                  "theme": {
                      "color": "#E0A854"
                  }
                };

                // @ts-ignore
                const rzp = new Razorpay(options);
                rzp.open();
              } else {
                // stripe
                // console.log('setStripeClientSecret: ', response);
                setStripeClientSecret(response.data.clientSecret);
                setStripeAmount(response.data.amount);
                setStripeModal(true);
              }
            } catch(e) {
              console.log(e);
              alert('there was an error!')
              setSubmitting(false)
            }
          }}
        >
          {({ values, isSubmitting }) => {
            return (
              <Form>
                <Row>
                  <Col sm>
                    <Field
                      type="text"
                      name="name"
                      required
                      component={Input}
                      label="Your Name"
                    />
                  </Col>
                  <Col sm>
                    <Field
                      type="number"
                      name="age"
                      required
                      component={Input}
                      label="Age"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                  <Field
                      type="text"
                      name="gender"
                      isMulti={false}
                      required
                      component={ReactSelect}
                      placeholder="Gender"
                      label="Gender"
                      options={genders.map((d: any) => ({
                        label: d,
                        value: d,
                      }))}
                    />
                  </Col>
                  <Col sm>
                    <Field
                      type="text"
                      name="city"
                      required
                      component={Input}
                      label="City"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                    <Field
                      type="text"
                      name="country"
                      isMulti={false}
                      required
                      component={ReactSelect}
                      placeholder="Nationality"
                      label="Country"
                      options={countries.map((d: any) => ({
                        label: d,
                        value: d,
                      }))}
                    />
                  </Col>
                  <Col sm>
                    <Field
                      type="email"
                      name="email"
                      required
                      component={Input}
                      label="Email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                    <Field
                      type="number"
                      name="phone"
                      required
                      component={Input}
                      label="Phone"
                    />
                  </Col>
                  <Col sm>
                    <Field
                      type="text"
                      name="verification"
                      required
                      component={Input}
                      label="Passport No./Aadhar Card No"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                    <Field
                      type="text"
                      name="mode"
                      required
                      isMulti={false}
                      component={ReactSelect}
                      placeholder="Select Mode"
                      label="Preferred mode for Teleconsultation"
                      options={modes.map((d: any) => ({
                        label: d,
                        value: d,
                      }))}
                    />
                  </Col>
                  <Col sm>
                    <Field
                      type="text"
                      name="schedule"
                      required
                      isMulti={false}
                      component={ReactSelect}
                      placeholder="Select Schedule"
                      label="Preferred Schedule for Teleconsultation"
                      options={schedule.map((d: any) => ({
                        label: d,
                        value: d,
                      }))}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm>
                    <Field
                      type="text"
                      name="doctor"
                      required
                      isMulti={false}
                      component={ReactSelect}
                      placeholder="Select a Doctor"
                      label="Select a Doctor"
                      options={doctors}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      type="textarea"
                      rows="5"
                      name="presentComplaint"
                      required
                      component={Input}
                      label="Present Complaint"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      type="file"
                      name="latestReports"
                      label="Upload Latest Reports"
                      component={FileUpload}
                      // required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field
                      type="textarea"
                      rows="5"
                      name="medicalHistory"
                      component={Input}
                      label="Medical History"
                      formtext="(Please provide detailed information so that an accurate plan of the treatment can be given)"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <RadioButtonGroup
                      id="language"
                      required
                      label="Preferred Language for Tele-Consultation:"
                    >
                      <Field
                        component={RadioButton}
                        name="language"
                        id="english"
                        label="English"
                        value="English"
                      />
                      <Field
                        component={RadioButton}
                        name="language"
                        id="arabic"
                        label="Arabic"
                        value="Arabic"
                      />
                    </RadioButtonGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Field
                      type="text"
                      name="payment_country"
                      isMulti={false}
                      required
                      component={ReactSelect}
                      placeholder="Nationality"
                      label="Payment Method Country"
                      options={payment_countries}
                    />
                  </Col>
                </Row>

                {
                  values.payment_country !== "IN" &&
                  <Row>
                    <Col>
                      <Field
                        type="text"
                        name="line1"
                        required
                        component={Input}
                        label="Address"
                      />
                    </Col>
                  </Row>
                }

                <Row>
                  <Col>
                    <Field
                      id="terms"
                      required
                      name="terms"
                      component={YesNo}
                      label={`I agree to the`}
                      linkOne="https://www.healthtravellersworldwide.com/terms-and-conditions/"
                      linkTwo="https://www.healthtravellersworldwide.com/privacy-policy/"
                      labelTwo="I hereby authorize Health Travellers Worldwide and their authorized partners to contact me through email, phone, or any other modes of communication. It will override my registry on NCPR"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span style={{ fontSize: 13, display: "block", paddingLeft: 10, marginBottom: 10 }}>*Teleconsultation is not advisable for any emergency situation. In case of an emergency please contact your nearest hospital and for emergency medical advice directly call us on our phone number at +91 73580 74795 (During working hours: 11 a.m - 5 p.m. IST)</span>
                    <div className="form-group">
                      <Button className="gold-btn" type="submit" disabled={isSubmitting}>
                        Submit
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Modal isOpen={stripeModal}>
                  <ModalBody>
                    <Elements stripe={stripePromise}>
                      <StripeCheckoutForm
                        clientSecret={stripeClientSecret}
                        amount={stripeAmount}
                        confirmPayment={async (id: string) => {
                          setStripeModal(false)
                          setProcessing(true);
                          await API.put('htwRestApi', `/confirmpayment/${id}`, { body: {}, headers: {} })
                          // redirect to payment success page
                          window.location.href = success;
                        }}
                        />
                    </Elements>
                  </ModalBody>
                </Modal>

                <Modal isOpen={processing}>
                  <ModalBody>
                    <Alert color="warning">Processing your payment, please wait.</Alert>
                  </ModalBody>
                </Modal>
                {/* {
                  showBtns && <Row>
                    <Col>
                      <div className="form-buttons-show">
                        <a className="btn gold-btn" rel="noopener noreferrer" target="_blank" href="https://wa.me/917358074795">
                          <span><i style={{ fontSize: 19 }} className="fab fa-whatsapp"></i></span>
                        </a>
                        <a className="btn gold-btn" rel="noopener noreferrer" target="_blank" href="tel:+91 73580 74795">
                          <span><i style={{ transform: "rotate(90deg)" }} className="fas fa-phone"></i></span>
                        </a>
                        <a id="tele-consult-btn" className="btn gold-btn" href="#">
                          <span><i className="fas fa-video"></i></span>
                        </a>
                      </div>
                      <div>
                        <span style={{ fontSize: 13, display: "block", paddingLeft: 10, marginBottom: 10 }}>*FREE Whatsapp Consultations provided. However, no eprescriptions will be given. For eprescriptions, kindly opt for consultation via phone or video.</span>
                      </div>
                    </Col>
                  </Row>
                } */}
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  );
}

export default TeleConsultation;
