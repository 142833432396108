import React from 'react';
import { Label } from "reactstrap";
import { FieldProps } from 'formik'

import FormikFieldCustomProps from './helpers/FormikFieldCustomProps'

const RadioButton: React.FC<FieldProps & FormikFieldCustomProps & { value: any }> = ({
  field: { name, value, onBlur },
  form: { setFieldValue },
  id,
  label,
  value: radioValue,
  ...props
}) => {
  return (
    <div className="formik-radio">
      <Label className={"label-color"} htmlFor={id}>
        <input
          name={name}
          id={id}
          type="radio"
          checked={radioValue === value}
          onChange={e => setFieldValue(name, radioValue)}
          onBlur={onBlur}
          className="radio-button"
          {...props}
        />
        <span>{label}</span>
      </Label>
    </div>
  );
};

export default RadioButton;