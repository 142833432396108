import React from 'react';
import { FormGroup, Label, FormFeedback } from "reactstrap";
import classNames from 'classnames';

const RadioButtonGroup:React.FC<{
  value?:any;
  error?:any;
  touched?:any;
  id?:any;
  label?:any;
  className?:any
  required?:any;
}> = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  ...props
}) => {
  const classes = classNames(
    "input-field",
    {
      "is-success": value || (!error && touched), // handle prefilled or user-filled
      "is-error": !!error && touched
    },
    className
  );

  return (
    <FormGroup className={classes}>
      <fieldset>
        {label && <Label className="control-label">{label} {props.required && <span className="text-danger">*</span>}</Label>}
        <div>
        {props.children}
        </div>
        {touched && error && <FormFeedback>{error}</FormFeedback>}
      </fieldset>
    </FormGroup>
  );
};

export default RadioButtonGroup;