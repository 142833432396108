import React, { useState } from "react";
import { Row, FormFeedback, FormGroup, Col, Progress } from "reactstrap";
import { FieldProps } from "formik";
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import FormikFieldCustomProps from "./helpers/FormikFieldCustomProps";

const FileUpload: React.FC<FormikFieldCustomProps & FieldProps & {
  accept: any;
  maxSize?: any;
  sizeWarning?: any;
}> = ({
  field: { value, ...field },
  form: {touched, errors, setFieldValue},
  ...props
}) => {
  const [progress, setProgress] = useState(1);
  const [filename, setFilename] = useState("");
  return (
    <Row>
      <Col md={4}>
        <FormGroup>
          <div className="fileUpload">
            <input
            {...props}
            {...field}
            onChange={(e) => {
              if(e.target.files) {
                const file = e.target.files[0];
                setFilename(file.name);
                Storage.put(`${uuidv4()}-${file.name}`, file, {
                  progressCallback(progress: any) {
                    setProgress(progress.loaded/progress.total)
                    // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                  },
                })
                .then ((result: any) => {
                  setProgress(1)
                  setFieldValue(field.name, result.key)
                })
                .catch(err => console.log(err));
              }
            }}
            size={40}
            className={`custom-file-upload-btn-single ${filename && "d-none"}`}
            accept=".pdf"
            aria-invalid="false"
             />
            {props.label}
          </div>
          <div className="attachment">
            <p><b>Attachment:</b><span className="file-chosen-text">{ filename || "No file chosen, yet." }</span></p>
          </div>
          {
            progress !== 1 && <Progress color="primary" value={progress * 100} />
          }
          {touched[field.name] && errors[field.name] ? (
            <FormFeedback>{errors[field.name]}</FormFeedback>
          ) : (
              ""
            )}
        </FormGroup>
      </Col>
      <Col md={3}>
        <span className="font-13 font-500">(Pdf format)</span>
      </Col>
    </Row>
  );
};

export default FileUpload;
