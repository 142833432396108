import React from "react";
import { FormGroup, Input as RInput, Label } from "reactstrap";
import { FieldProps } from "formik";

import FormikFieldCustomProps from "./helpers/FormikFieldCustomProps";

const YesNo: React.FC<FormikFieldCustomProps & FieldProps & { linkOne?: any; linkTwo?:any, labelTwo:string }> = ({
  field: { name, value },
  form: { touched, errors, setFieldValue },
  ...props
}) => (
  <FormGroup check>
    <Label for={name} className={"label-color"} check>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      <RInput
        type="checkbox"
        id={props.id}
        checked={value !== false}
        disabled={props.disabled || false}
        onChange={e => {
          setFieldValue(name, e.target.checked ? true : false);
          // onChange(e.target.checked)
        }}
      />
      <span className="c-box"></span>
      {
        props.label &&
        <span>{props.label}</span>
      }
      {
        props.linkOne && <a className="tc" target="_blank" rel="noopener noreferrer"  href={props.linkOne}>Terms &amp; Conditions</a>
      }
      {
        props.linkOne && props.linkTwo && <span className="and">and</span>
      }
      {
        props.linkTwo && <a className="tc" target="_blank" rel="noopener noreferrer" href={props.linkTwo}>Privacy Policy</a>
      }
      {/* {props.required && <span className="text-danger">*</span>} */}
      {
        props.labelTwo && <span>&nbsp;{props.labelTwo}</span>
      }
    </Label>
    {touched[name] && errors[name] ? <div style={{paddingLeft: 15}} className="error-sel">{errors[name]}</div> : ''}
    {/* <RInput type="text" value={value}></RInput> */}
  </FormGroup>
);

export default YesNo;
